import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Nav from "../components/Nav"
import MobileMenu from "../components/MobileMenu"
import PageTitle from "../components/ui/PageTitle"

import RenderBlock from "../components/RenderBlock"
import GoogleMap from "../components/GoogleMap"

const StyledPage = styled.div`
  @media (max-height: 1000px) {
    justify-content: flex-end !important;
  }
`

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - var(--navigation-height) - 227px);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-top: 90px;
  @media (max-height: 1000px) {
    padding-top: 0;
  }
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 0.6fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .section {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 768px) {
      width: calc(100% - 60px);
      margin: 0 30px;
    }
    &-middle {
      align-items: flex-end;
      justify-content: center;
      user-select: none;
      @media (max-width: 768px) {
        order: 5;
      }
      .hand-image {
        max-width: 200px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        @media (max-width: 1100px) {
          max-width: 150px;
        }
      }
    }
    &-left {
      flex-direction: column;
      margin-bottom: 40px;
      .left-top {
        height: 300px;
        margin-bottom: 8rem;
        @media (max-width: 768px) {
          height: 100%;
          margin-bottom: 50px;
        }
        .contact-details {
          margin-top: 20px;
          .wrap {
            margin-top: 15px;
            color: var(--white);
            font-weight: 300;
            .text {
              transition: 0.2s ease-in;
              position: relative;
              &:hover {
                color: var(--red);
                &:after {
                  background: var(--red);
                  bottom: -100%;
                  opacity: 0;
                }
              }
              &:after {
                content: "";
                width: 100%;
                height: 1px;
                background: var(--white);
                transition: 0.2s ease-in;
                position: absolute;
                left: 0;
                bottom: -3px;
                opacity: 1;
              }
            }
            &.email-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              .email-icon {
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background-image: url("../../email-icon-white.svg");
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
            &.phone-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              .phone-icon {
                display: flex;
                width: 20px;
                height: 20px;
                margin-right: 10px;
                background-image: url("../../phone-icon-white.svg");
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }
      }
      .left-bottom {
        @media (max-width: 768px) {
          width: 100%;
        }
        .social-content {
          margin-top: 20px;
          .social-wrap {
            display: flex;
            margin-top: 15px;
            color: var(--white);
            font-weight: 300;
            .text {
              transition: 0.2s ease-in;
              position: relative;
              &:hover {
                color: var(--red);
                &:after {
                  background: var(--red);
                  bottom: -100%;
                  opacity: 0;
                }
              }
              &:after {
                content: "";
                width: 100%;
                height: 1px;
                background: var(--white);
                transition: 0.2s ease-in;
                position: absolute;
                left: 0;
                bottom: -1px;
                opacity: 1;
              }
            }
            .icon {
              height: 20px;
              min-width: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    &-right {
      flex-direction: column;
      margin-bottom: 40px;
      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .right-top {
        margin-bottom: 8rem;
        height: 300px;
        width: 100%;
        @media (max-width: 768px) {
          order: 4;
          width: 100%;
          margin-bottom: 0px;
        }
      }
      .right-bottom {
        @media (max-width: 768px) {
          margin-bottom: 10px;
          width: 100%;
          text-align: center;
        }
        .location {
          display: flex;
          flex-direction: row;
          @media (max-width: 768px) {
            justify-content: center;
          }
          .pin-icon {
            display: flex;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            background-image: url("../../pin.svg");
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
    p {
      letter-spacing: 1.5px;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 20px;
      text-transform: uppercase;
      color: var(--red);
      letter-spacing: 1.3px;
      &::selection {
        color: var(--white);
      }
      @media (max-width: 768px) {
        text-align: center;
      }
    }
  }
`

export default function ContactPage({ data }) {
  const details = data.details,
    contactData = data.contact,
    social = data.social.nodes

  return (
    <StyledPage className="page">
      <Meta title="Contact us" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={contactData.pageTitle} />
          <StyledContent>
            <div className="section section-left">
              <div className="left-top">
                <RenderBlock
                  block={contactData.topLeftText}
                  className={"top-block"}
                />
                <div className="contact-details">
                  <a
                    href={`mailto:${details.email}`}
                    className="wrap email-wrap"
                    title="Email us now"
                  >
                    <span className="icon email-icon" />
                    <span className="text">{details.email}</span>
                  </a>
                  <a
                    href={`tel:${details.phone?.replace(" ", "")}`}
                    className="wrap phone-wrap"
                    title="Call us now"
                  >
                    <span className="icon phone-icon" />
                    <span className="text">{`+44 (0)${details.phone}`}</span>
                  </a>
                </div>
              </div>
              <div className="left-bottom">
                <RenderBlock
                  block={contactData.bottomLeftText}
                  className={"bottom-block"}
                />
                <div className="social-content">
                  {social.map((item, index) => {
                    return (
                      <a
                        href={item.url}
                        key={index}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-wrap"
                        title={`View our ${item.name} page`}
                      >
                        <Image
                          alt={`dark-spectrum-${item.name}`}
                          {...item.socialIcon}
                          className={`icon icon-${item.name.toLowerCase()}`}
                        />
                        <span className="text">{item.name}</span>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="section section-middle">
              <Image
                title={"Dark Spectrum rocks"}
                alt={"dark-spectrum-tattoo-rocks"}
                {...contactData.middleImage}
                className="hand-image"
              />
            </div>
            <div className="section section-right">
              {contactData.mapEnabled && (
                <div className="right-top">
                  <GoogleMap
                    lat={details.locationLatitude}
                    lng={details.locationLongitude}
                    markerIcon={details.markerIcon}
                    name={details.shortName}
                  />
                </div>
              )}
              <div className="right-bottom">
                <h3 className="location">
                  <span className="pin-icon" />
                  {details.locationPoint}
                </h3>
                <RenderBlock block={details.address} className={"address"} />
              </div>
            </div>
          </StyledContent>
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </StyledPage>
  )
}

export const siteDetails = graphql`
  query {
    details: sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      shortName
      copyrightMessage
      agencyName
      agencyLink
      email
      locationPoint
      locationLongitude
      locationLatitude
      markerIcon {
        ...ImageWithPreview
      }
      address {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
    }
    contact: sanityContact {
      pageTitle
      mapEnabled
      topLeftText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
      bottomLeftText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
      middleImage {
        ...ImageWithPreview
      }
    }
    social: allSanitySocialMedia {
      nodes {
        url
        name
        socialIcon {
          ...ImageWithPreview
        }
      }
    }
  }
`
