import React from "react"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"

const StyledMarker = styled.div`
  width: 120px;
  position: relative;
  img {
    height: 40px;
    position: absolute;
    top: -37px;
    left: -20px;
  }
  .name {
    color: var(--red);
    font-weight: bold;
    text-align: center;
    transform: translate(-50%, 5px);
    font-size: 13px;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 4px;
  }
`

const GoogleMapsMarker = ({ lat, lng, icon, name }) => {
  return (
    <StyledMarker>
      <p className="name">{name}</p>
      <Image
        alt={"dark-spectrum-tattoo-contact"}
        {...icon}
        className="marker-icon"
      />
    </StyledMarker>
  )
}

export default GoogleMapsMarker
