import React from "react"
import styled from "styled-components"
import GoogleMapReact from "google-map-react"
import GoogleMapsMarker from "./GoogleMapsMarker"

import { mapStyle } from "../utils/mapStyle"

const StyledMap = styled.div`
  width: 100%;
  height: 100%;
`

const GoogleMap = ({ lat, lng, markerIcon, name }) => {
  //   52.70782363692138, -2.7528721244133885

  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 15,
  }
  return (
    <StyledMap>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_MAPS_API_KEY.toString(),
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          styles: mapStyle,
        }}
      >
        <GoogleMapsMarker lat={lat} lng={lng} icon={markerIcon} name={name} />
      </GoogleMapReact>
    </StyledMap>
  )
}

export default GoogleMap
